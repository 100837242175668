<template>
  <v-container
    class="px-lg-10 mx-0 background-color: white"
    style="border: 1px solid grey"
  >
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="orange"
          dark
          class="mb-2"
          @click="cancelButtonClick"
        >
          KEMBALI
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Badan Usaha
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.name_legal }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Merek
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.name }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Alamat
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.address }}
        <br>
        {{ dataCustomerCompany.akec_string }}
        {{ dataCustomerCompany.akot_string }}
        {{ dataCustomerCompany.apro_string }}
        {{ dataCustomerCompany.apos }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Telepon Kantor
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.phone }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Direktur
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.director_name }}
      </v-col>
    </v-row>

    <v-row
      v-for="(val, i) in dataCustomerCompany.documents"
      :key="i"
    >
      <v-col
        v-if="i === 0"
        cols="12"
        sm="6"
        md="2"
      >
        Data
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="6"
        md="2"
      >
        <v-spacer />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-row>
          <v-col>
            {{ val.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-img
              v-if="val.file_type === 'image'"
              contain
              max-width="200"
              :src="val.file_path_view_only"
            />
            <iframe
              v-if="val.file_type === 'pdf'"
              width="600"
              height="400"
              :src="val.file_path_view_only"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="checkBoxValues[i]"
              :disabled="isDisableCheckbox[i]"
              true-value="1"
              false-value="0"
              class="mx-0 my-0 px-0 py-0"
              label="Telah sesuai"
              @click="checkboxCLick(val, i)"
            />
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </v-row>

    <v-row
      class="py-8"
    >
      <v-col
        class="text-right"
        cols="12"
        sm="6"
        md="6"
      >
        <v-btn
          :disabled="isDisableAcceptButton"
          color="primary"
          @click="verifyButtonClick(1)"
        >
          TELAH SESUAI
        </v-btn>
        <v-btn
          :disabled="isDisableRejectButton"
          color="red"
          @click="verifyButtonClick(0)"
        >
          BELUM SESUAI
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dataCustomerCompany: {
        hash: '',
        status: '1',
        status_string: 'Aktif',
        name: '',
        director_name: '',
        phone: '',
        address: '',
        akec_string: '',
        akot_string: '',
        apro_string: '',
        apos: '',
        verified: '2',
        owner: null,
      },
      checkBoxValues: [],
      documentParameter: {},
      isDisableCheckbox: [],
      isDisableAcceptButton: false,
      isDisableRejectButton: false,
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this

        const requestBody = {
          cust_com_hash: id,
        }

        axios.post('/t/customer/company/verification/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataCustomerCompany = res.data.data.detail
            this.setCheckBoxValues(vm.dataCustomerCompany.documents)
          }
        }).catch((e) => {
        })
      },

      setCheckBoxValues (documents) {
        documents.map((val, i) => {
          if (val.status === 1) {
            this.checkBoxValues.push('1')
            this.isDisableCheckbox.push(true)
          } else {
            this.checkBoxValues.push('0')
            this.isDisableCheckbox.push(false)
          }
        })
        this.setDisabledButton()
      },

      setDisabledButton () {
        if (this.checkBoxValues.includes('0')) {
          this.isDisableAcceptButton = true
          this.isDisableRejectButton = false
        }
        if (!this.checkBoxValues.includes('0')) {
          this.isDisableAcceptButton = false
          this.isDisableRejectButton = true
        }
      },

      checkboxCLick (data, i) {
        this.setDisabledButton()
      },

      verifyButtonClick (status) {
        this.dataCustomerCompany.documents.map((val, i) => {
          this.documentParameter[val.type] = this.checkBoxValues[i]
        })

        const requestBody = {
          cust_comp_hash: this.dataCustomerCompany.hash,
          submit_status: status,
          doc_arr: JSON.stringify(this.documentParameter),
        }

        axios.post('/t/customer/company/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            setTimeout(() => {
              this.$router.push({ name: 'CustomerCompaniesVerification' })
            }, 2500)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'CustomerCompaniesVerification' })
      },
    },
  }
</script>
